import { useRouter } from '../../../../hooks';

export function formatEvents(events) {
  const ROUTER = useRouter();

  if (!events || events.length === 0) {
    return [];
  }

  return events.map((event) => {
    const startDate = new Date(event.start_at);
    const formattedDate = startDate.toISOString().split('T')[0];

    if (event.end_at) {
      // Format to span across multiple days on calendar view
      const endDate = new Date(event.end_at);
      const formattedEndDate = endDate.toISOString().split('T')[0];

      return {
        title: event.title,
        start: formattedDate,
        end: formattedEndDate,
        url: `${ROUTER.PATH.EVENT_BASE}/${event.unique_id}`,
      };
    }

    return {
      title: event.title,
      date: formattedDate,
      url: `${ROUTER.PATH.EVENT_BASE}/${event.unique_id}`,
    };
  });
}
