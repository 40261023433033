import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import {
  PageLayout,
  DropdownMenu,
  Header,
  TrashIcon,
  PeopleIcon,
  Card,
} from '../../../ui';
import { DeleteProjectModal } from './components/DeleteProjectModal';
import { AddPeopleToProjectDialog } from './components/AddPeopleToProjectDialog';
import { useRequest, useRouter } from '../../../hooks';

import styles from './ProjectsShow.module.css';

export function ProjectsShow() {
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showPeopleModal, setShowPeopleModal] = useState(false);

  const ROUTER = useRouter();
  const { request, data } = useRequest();

  useEffect(() => {
    request({
      url: ROUTER.API.PROJECTS_SHOW,
    });
  }, []);

  const items = [
    {
      text: 'Project settings',
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.1741 21H8.4C6.15979 21 5.03968 21 4.18404 20.564C3.43139 20.1805 2.81947 19.5686 2.43597 18.816C2 17.9603 2 16.8402 2 14.6V9.4C2 7.15979 2 6.03968 2.43597 5.18404C2.81947 4.43139 3.43139 3.81947 4.18404 3.43597C5.03968 3 6.15979 3 8.4 3H8.71556C9.18517 3 9.41997 3 9.6331 3.06473C9.82179 3.12203 9.99732 3.21597 10.1497 3.34118C10.3217 3.48262 10.452 3.67798 10.7125 4.06872L11.2875 4.93128C11.548 5.32202 11.6783 5.51739 11.8503 5.65882C12.0027 5.78403 12.1782 5.87797 12.3669 5.93527C12.58 6 12.8148 6 13.2844 6H15.6C17.8402 6 18.9603 6 19.816 6.43597C20.5686 6.81947 21.1805 7.43139 21.564 8.18404C21.9256 8.89365 21.9873 9.78516 21.9978 11.3466M18 18H18.01M18 14L19.1787 15.1545L20.8284 15.1716L20.8455 16.8213L22 18L20.8455 19.1787L20.8284 20.8284L19.1787 20.8455L18 22L16.8213 20.8455L15.1716 20.8284L15.1545 19.1787L14 18L15.1545 16.8213L15.1716 15.1716L16.8213 15.1545L18 14Z'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      onClick: () => {
        ROUTER.NAVIGATE(ROUTER.PATH.PROJECTS_SETTINGS);
      },
    },
    {
      text: 'Add / remove people',
      icon: <PeopleIcon />,
      onClick: () => setShowPeopleModal(true),
    },
    {
      text: 'Delete project',
      icon: <TrashIcon />,
      onClick: () => setShowDeleteProjectModal(true),
      danger: true,
    },
  ];

  return (
    <PageLayout>
      <Header>
        <h1>
          <span className={clsx(styles.emoji)}>{data?.emoji}</span> {data?.name}
        </h1>
        <DropdownMenu items={items} />
      </Header>
      <div className={clsx(styles['grid-wrapper'])}>
        <ROUTER.LINK to={ROUTER.PATH.CHECKLISTS_INDEX}>
          <Card className={clsx(styles.card, 'flex flex-col')}>
            <svg
              className='mb-4'
              width='76'
              height='76'
              viewBox='0 0 76 76'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g filter='url(#filter0_d_1675_119)'>
                <rect
                  x='6'
                  y='2'
                  width='64'
                  height='64'
                  rx='24'
                  fill='url(#paint0_linear_1675_119)'
                />
                <g filter='url(#filter1_d_1675_119)'>
                  <path
                    d='M26 35.9474L33.4224 43.1198C33.8871 43.5688 34.6478 43.4698 34.995 42.9248C37.8754 38.404 44.1757 30.2972 51 27'
                    stroke='white'
                    stroke-width='5'
                    stroke-linecap='round'
                  />
                </g>
              </g>
              <defs>
                <filter
                  id='filter0_d_1675_119'
                  x='0'
                  y='0'
                  width='76'
                  height='76'
                  filterUnits='userSpaceOnUse'
                  color-interpolation-filters='sRGB'
                >
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='4' />
                  <feGaussianBlur stdDeviation='3' />
                  <feComposite in2='hardAlpha' operator='out' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='BackgroundImageFix'
                    result='effect1_dropShadow_1675_119'
                  />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow_1675_119'
                    result='shape'
                  />
                </filter>
                <filter
                  id='filter1_d_1675_119'
                  x='19.5'
                  y='21.4995'
                  width='38.0006'
                  height='29.4049'
                  filterUnits='userSpaceOnUse'
                  color-interpolation-filters='sRGB'
                >
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='1' />
                  <feGaussianBlur stdDeviation='2' />
                  <feComposite in2='hardAlpha' operator='out' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='BackgroundImageFix'
                    result='effect1_dropShadow_1675_119'
                  />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow_1675_119'
                    result='shape'
                  />
                </filter>
                <linearGradient
                  id='paint0_linear_1675_119'
                  x1='38'
                  y1='2'
                  x2='38'
                  y2='66'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='#43F6BD' />
                  <stop offset='1' stop-color='#00A4AA' />
                </linearGradient>
              </defs>
            </svg>

            <h2>Checklists</h2>
          </Card>
        </ROUTER.LINK>

        <ROUTER.LINK to={ROUTER.PATH.CALENDAR_INDEX}>
          <Card className={clsx(styles.card, 'flex flex-col')}>
            <svg
              className='mb-4'
              width='76'
              height='76'
              viewBox='0 0 76 76'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g filter='url(#filter0_d_1675_148)'>
                <rect
                  x='6'
                  y='2'
                  width='64'
                  height='64'
                  rx='24'
                  fill='url(#paint0_linear_1675_148)'
                />
                <rect
                  x='20'
                  y='32'
                  width='6'
                  height='6'
                  rx='3'
                  fill='#DADADA'
                />
                <rect
                  x='20'
                  y='45'
                  width='6'
                  height='6'
                  rx='3'
                  fill='#DADADA'
                />
                <rect
                  x='36'
                  y='32'
                  width='6'
                  height='6'
                  rx='3'
                  fill='#DADADA'
                />
                <rect
                  x='36'
                  y='45'
                  width='6'
                  height='6'
                  rx='3'
                  fill='#DADADA'
                />
                <rect
                  x='51'
                  y='32'
                  width='6'
                  height='6'
                  rx='3'
                  fill='#DADADA'
                />
                <rect
                  x='51'
                  y='45'
                  width='6'
                  height='6'
                  rx='3'
                  fill='#DADADA'
                />
              </g>
              <path
                d='M6 21C6 10.5066 14.5066 2 25 2H51C61.4934 2 70 10.5066 70 21V21H6V21Z'
                fill='url(#paint1_linear_1675_148)'
              />
              <g filter='url(#filter1_i_1675_148)'>
                <rect
                  x='30'
                  y='9'
                  width='16'
                  height='5'
                  rx='2.5'
                  fill='white'
                />
              </g>
              <defs>
                <filter
                  id='filter0_d_1675_148'
                  x='0'
                  y='0'
                  width='76'
                  height='76'
                  filterUnits='userSpaceOnUse'
                  color-interpolation-filters='sRGB'
                >
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='4' />
                  <feGaussianBlur stdDeviation='3' />
                  <feComposite in2='hardAlpha' operator='out' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='BackgroundImageFix'
                    result='effect1_dropShadow_1675_148'
                  />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow_1675_148'
                    result='shape'
                  />
                </filter>
                <filter
                  id='filter1_i_1675_148'
                  x='30'
                  y='9'
                  width='16'
                  height='6'
                  filterUnits='userSpaceOnUse'
                  color-interpolation-filters='sRGB'
                >
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='BackgroundImageFix'
                    result='shape'
                  />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='1' />
                  <feGaussianBlur stdDeviation='0.5' />
                  <feComposite
                    in2='hardAlpha'
                    operator='arithmetic'
                    k2='-1'
                    k3='1'
                  />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='shape'
                    result='effect1_innerShadow_1675_148'
                  />
                </filter>
                <linearGradient
                  id='paint0_linear_1675_148'
                  x1='38'
                  y1='17'
                  x2='38'
                  y2='79'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='white' />
                  <stop offset='1' stop-color='white' />
                </linearGradient>
                <linearGradient
                  id='paint1_linear_1675_148'
                  x1='38'
                  y1='2'
                  x2='38'
                  y2='21'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='#FF2C48' />
                  <stop offset='1' stop-color='#CC0022' />
                </linearGradient>
              </defs>
            </svg>

            <h2>Calendar</h2>
          </Card>
        </ROUTER.LINK>

        <ROUTER.LINK to={ROUTER.PATH.POSTS_CATEGORIES_INDEX}>
          <Card className={clsx(styles.card, 'flex flex-col')}>
            <svg
              className='mb-4'
              width='76'
              height='76'
              viewBox='0 0 76 76'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g filter='url(#filter0_d_1675_150)'>
                <rect
                  x='6'
                  y='2'
                  width='64'
                  height='64'
                  rx='24'
                  fill='url(#paint0_linear_1675_150)'
                />
                <g filter='url(#filter1_d_1675_150)'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M31 20C26.0294 20 22 24.0294 22 29V36C22 40.9706 26.0294 45 31 45H31.8143L33.3654 50.7937C33.5738 51.5723 34.5705 51.797 35.0929 51.1832L40.3551 45H46C50.9706 45 55 40.9706 55 36V29C55 24.0294 50.9706 20 46 20H31Z'
                    fill='url(#paint1_linear_1675_150)'
                  />
                </g>
              </g>
              <defs>
                <filter
                  id='filter0_d_1675_150'
                  x='0'
                  y='0'
                  width='76'
                  height='76'
                  filterUnits='userSpaceOnUse'
                  color-interpolation-filters='sRGB'
                >
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='4' />
                  <feGaussianBlur stdDeviation='3' />
                  <feComposite in2='hardAlpha' operator='out' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='BackgroundImageFix'
                    result='effect1_dropShadow_1675_150'
                  />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow_1675_150'
                    result='shape'
                  />
                </filter>
                <filter
                  id='filter1_d_1675_150'
                  x='18'
                  y='18'
                  width='41'
                  height='39.5356'
                  filterUnits='userSpaceOnUse'
                  color-interpolation-filters='sRGB'
                >
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='2' />
                  <feGaussianBlur stdDeviation='2' />
                  <feComposite in2='hardAlpha' operator='out' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='BackgroundImageFix'
                    result='effect1_dropShadow_1675_150'
                  />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow_1675_150'
                    result='shape'
                  />
                </filter>
                <linearGradient
                  id='paint0_linear_1675_150'
                  x1='41'
                  y1='2'
                  x2='15'
                  y2='63.5'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='#3AE5FF' />
                  <stop offset='1' stop-color='#2C75B4' />
                </linearGradient>
                <linearGradient
                  id='paint1_linear_1675_150'
                  x1='38.5'
                  y1='20'
                  x2='38.5'
                  y2='51.5356'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='white' />
                  <stop offset='1' stop-color='#CBEDFF' />
                </linearGradient>
              </defs>
            </svg>

            <h2>Posts</h2>
          </Card>
        </ROUTER.LINK>

        <ROUTER.LINK to={ROUTER.PATH.FILES_INDEX}>
          <Card className={clsx(styles.card, 'flex flex-col')}>
            <svg
              className='mb-4'
              width='76'
              height='76'
              viewBox='0 0 76 76'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g filter='url(#filter0_d_1675_160)'>
                <rect
                  x='6'
                  y='2'
                  width='64'
                  height='64'
                  rx='24'
                  fill='url(#paint0_linear_1675_160)'
                />
                <g filter='url(#filter1_d_1675_160)'>
                  <path
                    d='M48.4007 34.639L43.2088 43.6316C40.751 47.8887 35.3075 49.3472 31.0505 46.8894C26.7935 44.4316 25.3349 38.9882 27.7927 34.7312L35.2098 21.8845C36.8483 19.0465 40.4773 18.0741 43.3153 19.7126C46.1533 21.3512 47.1257 24.9801 45.4871 27.8181L38.0701 40.6648C37.2508 42.0838 35.4364 42.57 34.0173 41.7508C32.5983 40.9315 32.1122 39.117 32.9314 37.698L39.6068 26.136'
                    stroke='url(#paint1_linear_1675_160)'
                    stroke-width='3'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </g>
              </g>
              <defs>
                <filter
                  id='filter0_d_1675_160'
                  x='0'
                  y='0'
                  width='76'
                  height='76'
                  filterUnits='userSpaceOnUse'
                  color-interpolation-filters='sRGB'
                >
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='4' />
                  <feGaussianBlur stdDeviation='3' />
                  <feComposite in2='hardAlpha' operator='out' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='BackgroundImageFix'
                    result='effect1_dropShadow_1675_160'
                  />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow_1675_160'
                    result='shape'
                  />
                </filter>
                <filter
                  id='filter1_d_1675_160'
                  x='16'
                  y='14'
                  width='43'
                  height='43'
                  filterUnits='userSpaceOnUse'
                  color-interpolation-filters='sRGB'
                >
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='2' />
                  <feGaussianBlur stdDeviation='2' />
                  <feComposite in2='hardAlpha' operator='out' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='BackgroundImageFix'
                    result='effect1_dropShadow_1675_160'
                  />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow_1675_160'
                    result='shape'
                  />
                </filter>
                <linearGradient
                  id='paint0_linear_1675_160'
                  x1='41'
                  y1='2'
                  x2='15'
                  y2='63.5'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='#FFC30E' />
                  <stop offset='1' stop-color='#FF6A00' />
                </linearGradient>
                <linearGradient
                  id='paint1_linear_1675_160'
                  x1='30.5'
                  y1='24'
                  x2='43.5'
                  y2='50.5'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='white' />
                  <stop offset='1' stop-color='#F2F2F2' />
                </linearGradient>
              </defs>
            </svg>

            <h2>Files</h2>
          </Card>
        </ROUTER.LINK>
      </div>
      <DeleteProjectModal
        open={showDeleteProjectModal}
        onOpenChange={() => setShowDeleteProjectModal(false)}
      />
      <AddPeopleToProjectDialog
        open={showPeopleModal}
        onOpenChange={() => setShowPeopleModal(false)}
      />
    </PageLayout>
  );
}
